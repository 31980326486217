<template>
  <a-table :columns="columns" :source="source" bordered overflow>
    <template #toolbar(search)>
        <div class="space-x-2 flex items-center p-2" v-if="specialization">
            <span class="inline-block rounded-full h-4 w-4" :style="{ background: specialization.color }"></span>
            <h4 class="inline-block text-2xl">
                {{ specialization.title }}
            </h4>
        </div>
    </template>

    <!--                    <template #toolbar(per-page)>-->
    <!--                        <a-segmented-control v-model="control" :segments="segments"/>-->
    <!--                    </template>-->

    <template #td(code)="{ item }">
      {{ mappedCode(item) }}
    </template>
    <template #td(semester)="{ item }">
      {{ mappedSemester(item) }}
    </template>
<!--    <template v-slot:semester_number>-->
<!--      <select v-model="filters.semester" class="p-3 border border-gray-200 shadow-sm focus:outline-none focus:ring-2 focus:ring-primary rounded-lg w-full">-->
<!--        <option value="">&#45;&#45; TODOS &#45;&#45;</option>-->
<!--       <option :value="semester" v-for="(semester, k) in semesters" :key="k">{{ semester }}</option>-->
<!--      </select>-->
<!--    </template>-->
    <!--                    <template v-slot:section>-->
    <!--                        <select v-model="filters.section" class="p-3 border border-gray-200 shadow-sm focus:outline-none focus:ring-2 focus:ring-primary rounded-lg w-full">-->
    <!--                            <option value="">&#45;&#45; TODOS &#45;&#45;</option>-->
    <!--                            <option :value="section" v-for="(section, k) in sections" :key="k">{{ section }}</option>-->
    <!--                        </select>-->
    <!--                    </template>-->
    <template #td(actions)="{ item }">
      <courses-details-table-dropdown
        :course-section="item"
        :teachers="teachers"
        @fetch-courses="$emit('fetch-courses')"
        @generate-lesson="modals.generateLesson = $event"
        @handle-table="$emit('handle-table', $event);"/>
    </template>
  </a-table>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import CoursesDetailsTableDropdown from '../components/courses-details-table-dropdown.vue'

export default {
  name: 'table',
  components: {
    CoursesDetailsTableDropdown,
  },
  props: {
    specialization: { required: false },
    source: { required: false, type: Array, default: () => [] },
    courseSections: { required: false, type: Array, default: () => [] },
    loadingCourseSections: { required: false, type: Boolean, default: false }
  },
  data: () => ({
    segments: [
      { id: 0, title: "Activos" },
      { id: 1, title: "Inactivos" },
    ],
    // courses: [],
    body: {
      'specialization_id': 0,
      'period_id': 0,
      'pensum_id': 0,
      'enabled': 0
    },
    filters: {
      semester: null,
      section: null
    },
    modals: {
      generateLesson: {}
    },
    control: 0,
    loadingCourses: false
  }),
  computed: {
    ...mapState({
      user: ({ session }) => session.user,
      loading: state => state.periods.loading,
      loadingTeachers: ({ courseSections }) => courseSections.loading,
      teachers: ({ teachers }) => teachers.all
    }),
    columns() {
      return [
        { title: "Código", slot: "code", display: true },
        { title: "Materia", key: "title", filter: false, display: true },
        { title: "Secciones", key: "course_sections.length", filter: false, keyFilter: 'section', display: true },
        { title: "Semestre", slot: "semester", filter: false, columnFilterable: true, display: true },
        { title: "Créditos", key: "credits", display: true },
        // { title: "Estado", key: "enabled", mutate: ({ key }) => key ? 'Activa' : 'Inactiva', display: true },
        { title: "Acciones", slot: "actions", display: this.user.isAdminGroup },
      ].filter($0 => $0.display)
    },
    // specializations() {
    //     let data = []
    //     let courses = []
    //     this.courseSections.forEach($0 => {
    //         if (!data.some($1 => $1.id === $0.specialization.id)) {
    //             data.push({
    //                 ...$0.specialization,
    //                 courses: this.courseSections
    //                     .filter($1 => {
    //                         console.log($1.specialization.id)
    //                         return $1.specialization.id === $0.specialization.id
    //                     })
    //                     .map($1 => {
    //                         return ({
    //                             ...$1.course,
    //                             course_id: $1.course.id,
    //                             section_id: $1.section.id,
    //                             course_section_id: $1.id,
    //                             section: $1.section,
    //                             semester_number: $1.semester.number,
    //                             teacher: $1.teacher,
    //                             letter_code: $1.section.letter_code
    //                         })
    //                     })
    //             })
    //         }
    //
    //         let specialization = data.find($1 => $0.specialization.id === $1.id)
    //         if (specialization) {
    //
    //             const course = specialization.courses.find($1 => $1.id === $0.id)
    //
    //             if (course) {
    //                 if (course.course_section_id === $0.course_section_id) {
    //                     specialization.courses.push({
    //                         ...$0.course,
    //                         course_section_id: $0.id,
    //                         section: $0.section,
    //                         semester_number: $0.semester.number,
    //                         teacher: $0.teacher
    //                     })
    //                 }
    //             } else {
    //
    //                 specialization.courses.forEach($1 => {
    //                     if (!courses.some($2 => $2.course_section_id === $1.course_section_id)) {
    //                         courses.push($1)
    //                     }
    //                 })
    //
    //                 specialization.courses = courses
    //             }
    //             courses = []
    //         }
    //     })
    //
    //     return data
    // },
    // courses() {
    //     return this.specialization.courses
    // },
    // specialization() {
    //     const { specialization } = this.$route.params
    //     if (specialization) {
    //         return this.specializations?.find($0 => $0.id === specialization)
    //     }
    //
    //     return null
    // },
    // semesters() {
    //     let semesters = new Set(this.specialization.courses.flatMap($0 => $0.semester_number))
    //     return [...semesters].map($0 => $0)
    // },
    // sections() {
    //     let sections = new Set(this.specialization.courses.flatMap($0 => $0.section.letter_code))
    //     return [...sections]
    // },
    filteredCoursesBySemester() {
      if (this.filters.semester) {
        return this.courses.filter($0 => this.$options.filters.roman($0.semester_number) === this.filters.semester)
      }

      return this.courses
    },
    filteredCourses() {
      const { semester, section } = this.filters
      return this.courses
        .filter($0 => semester ? $0.semester_number === semester : true)
        .filter($0 => section ? $0.section.letter_code === section : true)
        .filter($0 => this.control ? $0.enabled === (this.control === 0) : true)
        .sort((a, b) => a.title > b.title ? 1 : -1)
    }
    // filteredStatus() {
    //     // console.log(this.courses)
    //     let status = this.courses && 0
    //     console.log(status)
    //     return this.courses
    //         .filter($0 => {
    //             // console.log($0.enabled)
    //             return status ? $0.enabled === status : true
    //         })
    //         .sort((a, b) => a.title > b.title ? 1 : -1)
    // }
  },
  methods: {
    ...mapActions({
      fetchTeachers: 'teachers/index'
    }),
    mappedCode(item) {
      const code = item.semesters.flatMap(($0) => {
        if ($0.specialization_id === parseInt(this.$route.params.specialization)) {
          return $0.pivot.code
        } else {
          return []
        }
      })
      return code[0]
    },
    mappedSemester(item) {
      const code = item.semesters.flatMap(($0) => {
        if ($0.specialization_id === parseInt(this.$route.params.specialization)) {
          return $0.number
        } else {
          return []
        }
      })
      return code[0]
    }
  },
  watch: {
    '$route.params.specialization_id'() {
      this.filters.semester = null
      this.filters.section = null
    }
    
  },
  mounted(){
    function comparar(a, b) {
      if (a.semesters[0].number < b.semesters[0].number) {
        return -1;
      }
      if (a.semesters[0].number > b.semesters[0].number) {
        return 1;
      }
      return 0;
    }

    this.source.sort( comparar )
  }
  // mounted() {
  //   // this.fetchTeachers({ limit: 0, enabled: true })
  //   this.$emit('specialization', parseInt(this.$route.params.specialization))
  //   this.body = {
  //     'specialization_id': parseInt(this.$route.params.specialization),
  //     'period_id': parseInt(this.$route.params.period),
  //     'pensum_id': parseInt(this.$route.params.pensum),
  //     'enabled': true
  //   }
  //   this.loadingCourses = true
  //   this.$repository.specializations
  //     .courses(this.body)
  //     .then(({ data }) => {
  //       this.courses = data
  //       console.log(data)
  //     })
  //     .finally(() => (this.loadingCourses = false))
  // }
}
</script>
