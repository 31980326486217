<template>
  <div class="flex space-x-2">
<!--    <a-dropdown v-if="filteredTeachers.length" class="inline">-->
    <a-dropdown class="inline">
      <template #menu>
        <template>
<!--          <a-dropdown-item :to="{ name: 'courses.show', params: { period: period, specialization: specialization, pensum: pensum } }">-->
<!--            Ver-->
<!--          </a-dropdown-item>-->
          <a-dropdown-item @click="$emit('handle-table', ['course-show', courseSection])">
            Ver
          </a-dropdown-item>
          <a-dropdown-item v-if="user.isAdmin" @click="editModal = true">
            Editar
          </a-dropdown-item>
          <a-dropdown-item :href="`${url}/api/semesters/${courseSection.semesters[0].id}/reports/list-students`">
<!--            <a target="_blank" >-->
<!--              Reporte de estudiantes-->
<!--            </a>-->
            Reporte de estudiantes
          </a-dropdown-item>
          <a-dropdown-item v-if="user.isAdmin" :to="{ name: 'courses.create', query: { update: courseSection.id } }">
            Actualizar materia
          </a-dropdown-item>
<!--          <a-dropdown-item prevent-close @click="$emit('generate-lesson', { course_id: courseSection.course_id, section_id: courseSection.section_id })">-->
<!--            Generar clase-->
<!--          </a-dropdown-item>-->
<!--          <a-dropdown-item v-if="filteredTeachers.length" @click.stop="assigning = !assigning" prevent-close forward>-->
<!--            {{ courseSection.teacher ? 'Cambiar' : 'Asignar' }} profesor-->
<!--          </a-dropdown-item>-->
        </template>
<!--        <template v-else>-->
<!--          <a-dropdown-item @click.stop="assigning = !assigning" prevent-close back>-->
<!--            Atras-->
<!--          </a-dropdown-item>-->
<!--          <div v-if="filteredTeachers.length">-->
<!--            <a-dropdown-item-->
<!--                v-for="teacher in filteredTeachers"-->
<!--                :key="teacher.id"-->
<!--                @click="assignTeacher({ course_id: courseSection.id, section_id: courseSection.section.id, teacher })">-->
<!--              {{ `${teacher.first_name} ${teacher.last_name}` }}-->
<!--            </a-dropdown-item>-->
<!--          </div>-->
<!--        </template>-->
      </template>
    </a-dropdown>
<!--    <a-loader v-else />-->
    <a-modal
      :show="editModal"
      :title="'Editar código'"
      @confirm="editCourse"
      @cancel="editModal = false"
    >
      <a-input
        type="select"
        v-model="code" />
    </a-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { URL } from '@/constants'
export default {
  props: {
    courseSection: { required: true, type: Object },
    teachers: { required: false, type: Array, default: () => [] },
    period: { required: false, type: Number, default: 1 },
    pensum: { required: false, type: Number },
    specialization: { required: false, type: Number }
  },
  data: () => ({
    url: URL,
    assigning: false,
    loadings: {
      assign: false
    },
    editModal: false,
    code: ''
  }),
  methods: {
    ...mapActions({
      assign: 'sections/assignTeacherToSectionCourse',
    }),
    editCourse() {
      this.$repository.semesters
        .courseSemesterUpdate(this.courseSection.semesters[0].id, this.courseSection.id, { code: this.code })
        .then(() => {
          this.$message.success({
            message: `Se ha actualizado la materia con éxito`,
            posdataon: `top-right`
          })
          this.$emit('fetch-courses')
        })
      // // this.finalEvaluationsModal = false
    },
    assignTeacher({ section_id, course_id, teacher }) {
      this.assign({ section_id, course_id, teacher_id: teacher.id })
          .then(() => {
            this.$message.success({
              message: `Se ha asignado el profesor con éxito`,
              posdataon: `top-right`
            })

            this.assigning = false

            this.$emit('teacher-assigned')
          })
    },
  },
  computed: {
    ...mapState({
      user: state => state.session.user
    }),
    filteredTeachers () {
      return this.teachers.filter($0 => $0.id !== this.courseSection.teacher?.id)
    }
  }
}
</script>
