<template>
  <a-modal
    title="Actualizar curso"
    :show="show"
    @confirm="confirm()"
    @cancel="$emit('cancel')">

    <div class="space-y-4">
<!--      <a-input-->
<!--        id="start_date"-->
<!--        type="date"-->
<!--        :error="error('start_date')"-->
<!--        @input="clear('start_date')"-->
<!--        label="Fecha de inicio"-->
<!--        v-model="form.start_date" />-->
      <label class="mb-2 block font-medium text-gray-700" for="calculated_avg">¿Entrega final?</label>
      <a-switch class="mb-4" id="calculated_avg" v-model="form.final_delivery" />
<!--      <a-select-->
<!--        label="Corte de periodo"-->
<!--        :error="error('period_cut')"-->
<!--        @input="clear('period_cut')"-->
<!--        :source="[{ label: 'Primer corte', value: 1 }, { label: 'Segundo corte', value: 2 }]"-->
<!--        v-model="form.period_cut" />-->
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'course-section-update',
  props: {
    show: { required: true, type: Boolean },
    courseSection: { required: true, type: Object },
  },
  data: () => ({
    form: {
      final_delivery: false,
    },
    loadings: {
      courseSections: false,
      store: false
    }
  }),
  watch: {
    courseSection(value) {
      this.form.final_delivery = value.final_delivery
    }
  },
  methods: {
    confirm () {
      const { section_id, course_id } = this.courseSection

      this.$repository.courseSections
        .updateCourseSection(section_id, course_id, { ...this.form })
        .then(() => {
          this.$message.success({
            message: `Se ha actualizado la clase con exito`,
            posdataon: `top-right`
          })
          this.$emit('fetch-courses')
        })
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped>

</style>
