<template>
  <div class="flex space-x-2">
    <!--    <a-dropdown v-if="filteredTeachers.length" class="inline">-->
    <a-dropdown class="inline" search>
      <template #menu>
        <template v-if="!assigning">
          <!--          <a-dropdown-item :to="{ name: 'courses.show', params: { period: period, specialization: specialization, pensum: pensum } }">-->
          <!--            Ver-->
          <!--          </a-dropdown-item>-->
<!--          <a-dropdown-item @click="$emit('handle-table', ['course-show', courseSection])">-->
<!--            Ver-->
<!--          </a-dropdown-item>-->
<!--          <a-dropdown-item :to="{ name: 'courses.create', query: { update: courseSection.id } }">-->
<!--            Actualizar materia-->
<!--          </a-dropdown-item>-->
            <a-dropdown-item v-if="user.isAdmin" prevent-close @click="$emit('register-lesson', { course_id: courseSection.course_id, section_id: courseSection.section_id })">
              Registrar clase
            </a-dropdown-item>
            <a-dropdown-item v-if="courseSection.teacher" prevent-close @click="$router.push({ name: 'courses.lessons', params: { course_id: courseSection.course_id, section_id: courseSection.section_id, teacher: courseSection.teacher.id}})">
              Ver clases
            </a-dropdown-item>
            <a-dropdown-item prevent-close v-if="user.isAdmin" @click="$emit('generate-lesson', { course_id: courseSection.course_id, section_id: courseSection.section_id })">
              Generar clase
            </a-dropdown-item>
            <a-dropdown-item v-if="user.isAdmin" @click="$emit('update-lesson', { course_id: courseSection.course_id, section_id: courseSection.section_id, final_delivery: courseSection.final_delivery})">
              Actualizar curso
            </a-dropdown-item>
            <a-dropdown-item v-if="user.isAdmin" :to="{ name: 'schedules', query: { specialization: courseSection.specialization_id, semester: courseSection.semester_id, section: courseSection.section_id, course: courseSection.course_id, period: courseSection.period_id }}">
              Asignar horario
            </a-dropdown-item>
            <a-dropdown-item :href="`${url}/api/students/reports/list-students?course_id=${courseSection.course_id}&semester_id=${courseSection.semester_id}`">
              <!--            <a target="_blank" >-->
              <!--              Reporte de estudiantes-->
              <!--            </a>-->
              Imprimir estudiantes
            </a-dropdown-item>
            <a-dropdown-item v-if="user.isAdmin && filteredTeachers.length" @click.stop="openActions" prevent-close forward>
              {{ courseSection.teacher ? 'Cambiar' : 'Asignar' }} profesor
            </a-dropdown-item>
        </template>
          <template v-else>
            <a-dropdown-item @click.stop="assigning = !assigning" prevent-close back>
              Atras
            </a-dropdown-item>
            <div v-if="filteredTeachers.length">
                <a-input type="search" class="px-2" v-model="search"/>
                <a-dropdown-item
                  v-for="teacher in teach"
                  :key="teacher.id"
                  @click="assignTeacher({ course_id: courseSection.course_id, section_id: courseSection.section_id, teacher_id: teacher.id })">
                {{ `${teacher.first_name} ${teacher.last_name}` }}
              </a-dropdown-item>
            </div>
          </template>
      </template>
    </a-dropdown>
    <!--    <a-loader v-else />-->
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import { URL } from '@/constants'
export default {
  props: {
    courseSection: { required: true, type: Object },
    teachers: { required: false, type: Array, default: () => [] },
    period: { required: false, type: Number, default: 1 },
    pensum: { required: false, type: Number },
    specialization: { required: false, type: Number }
  },
  data: () => ({
    url: URL,
    assigning: false,
    search: '',
    teach: [],
    loadings: {
      assign: false
    }
  }),
  methods: {
    ...mapActions({
      assign: 'sections/assignTeacherToSectionCourse',
    }),
    openActions() {
      this.assigning = !this.assigning
      this.search = ''
      this.teach = this.filteredTeachers
    },
    assignTeacher({ section_id, course_id, teacher_id }) {
      // console.log(section_id, course_id, teacher)
      this.assign({ section_id, course_id, teacher_id})
        .then(() => {
          this.$message.success({
            message: `Se ha asignado el profesor con éxito`,
            posdataon: `top-right`
          })

          this.assigning = false
          this.$emit('teacher-assigned')
        })
    },
  },
  computed: {
    ...mapState({
      user: state => state.session.user
    }),
    filteredTeachers () {
      return this.teachers.filter($0 => $0.id !== this.courseSection.teacher?.id)
    }
  },
  watch: {
    search(e) {
      this.teach = this.filteredTeachers.filter($0 => $0.first_name.toLowerCase().includes(e.toLowerCase()) || $0.last_name.toLowerCase().includes(e.toLowerCase()))
    }
  },
}
</script>
