<template>
  <a-modal
    :disabled-confirm="isConfirmDisabled"
    title="Crear clase"
    :show="show"
    @confirm="confirm"
    @cancel="$emit('cancel')">

    <div class="space-y-4">
      <a-input
        id="start_date"
        @input="clear('subject')"
        label="Tema"
        v-model="form.subject" />

      <a-input
        id="start_date"
        type="date"
        :error="error('date')"
        @input="clear('date')"
        label="Fecha de inicio"
        v-model="form.date" />

      <a-select
        label="Corte de periodo"
        :error="error('period_cut')"
        @input="clear('period_cut')"
        :source="[{ label: 'Primer corte', value: 1 }, { label: 'Segundo corte', value: 2 }]"
        v-model="form.period_cut" />

      <div class="flex">
        <div class="flex-1">
          <h2 class="font-medium block">Evaluado</h2>
          <a-switch
              id="evaluated"
              @input="clear('evaluated')"
              v-model="evaluated" >
          </a-switch>
        </div>
        <div v-if="evaluated" class="flex-1">
          <h2 class="font-medium block">Ponderación</h2>
          <input
              id="ponderation"
              type="number"
              :min="0"
              class="p-2 border border-dgray rounded-md"
              @input="clear('evaluated')"
              v-model="ponderation" >
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
export default {
  props: {
    show: { required: true, type: Boolean },
    courseSection: { required: true, type: Object },
  },
  data: () => ({
    form: {
      subject: '',
      date: '',
      period_cut: '',
    },
    evaluated: false,
    ponderation: 0,
    course_section_id: 0,
    loadings: {
      courseSections: false,
      store: false
    }
  }),
  computed: {
    ...mapGetters({
      error: 'error'
    }),
    ...mapState({
      user: ({ session }) => session.user
    }),
    isConfirmDisabled() {
      return Object.values(this.form).some(value => !value)
    },
    // minDate() {
    //   return
    // }
  },
  methods: {
    ...mapActions({
      clear: 'removeError'
    }),
    confirm() {
      this.$emit('cancel')
      const { section_id, course_id } = this.courseSection

      this.$repository.sections
        .courseSection(section_id, course_id)
        .then(({ data }) => {

          const body = {
            ...this.form,
            evaluated: this.evaluated,
            course_section_id: data.id,
            evaluation_weighting_prc: parseInt(this.ponderation)
          }

          this.$repository.lessons
            .store(body)
            .then(() => {
              this.$message.success({
                message: `Se ha registrado la clase con exito`,
                posdataon: `top-right`
              })
              this.$emit('cancel')
            })
        })
    },
  }
}
</script>
