<template>
  <a-modal
    :title="'Editar clase'"
    :show="show"
    @confirm="confirm"
    @cancel="$emit('cancel')">

    <div class="space-y-4">
      <a-input
        id="start_date"
        @input="clear('subject')"
        label="Tema"
        v-model="form.subject" />

      <a-input
        v-if="!isEditing"
        id="start_date"
        type="date"
        :error="error('date')"
        @input="clear('date')"
        label="Fecha de inicio"
        v-model="form.date" />

      <a-select
        v-if="!isEditing"
        label="Corte de periodo"
        :error="error('period_cut')"
        :disabled="isEditing"
        @input="clear('period_cut')"
        :source="[{ label: 'Primer corte', value: 1 }, { label: 'Segundo corte', value: 2 }]"
        v-model="form.period_cut" />

      <div class="flex">
        <div class="flex-1">
          <h2 class="font-medium block">Evaluado</h2>
          <a-switch
            id="evaluated"
            @input="clear('evaluated')"
            v-model="evaluated" >
          </a-switch>
        </div>
        <div v-if="evaluated" class="flex-1">
          <h2 class="font-medium block">Ponderación</h2>
          <input
            id="ponderation"
            type="number"
            :min="0"
            class="p-2 border border-dgray rounded-md"
            @input="clear('evaluated')"
            v-model="ponderation" >
        </div>
      </div>
<!--      <div class="flex-1" v-else>-->
<!--        <div class="flex-1">-->
<!--          <h2 class="font-medium block">Evaluado</h2>-->
<!--          <a-switch-->
<!--              id="evaluated"-->
<!--              disabled-->
<!--              @input="clear('evaluated')"-->
<!--              v-model="evaluated" >-->
<!--          </a-switch>-->
<!--        </div>-->
<!--        <h2 class="font-medium block">Ponderación</h2>-->
<!--        <input-->
<!--            id="editingPonderation"-->
<!--            type="number"-->
<!--            :min="0"-->
<!--            class="p-2 border border-dgray rounded-md"-->
<!--            @input="clear('evaluated')"-->
<!--            v-model="ponderation" >-->
<!--      </div>-->
    </div>
  </a-modal>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import moment from "@/filters/moment";
export default {
  name: 'edit-class',
  props: {
    show: { required: true, type: Boolean },
    courseSection: { required: false },
    isEditing: { required: false, default: false }
  },
  emits: ['cancel'],
  data: () => ({
    form: {
      subject: '',
      date: '',
      period_cut: null,
    },
    body: {},
    evaluated: false,
    course_section_id: 0,
    ponderation: 0,
    loadings: {
      courseSections: false,
      store: false
    }
  }),
  // mounted() {
  //   this.form.date = moment(this.courseSection?.registered_at, 'YYYY-MM-DD')
  //   this.form.subject = this.courseSection.subject
  //   this.form.period_cut = this.courseSection.period_cut
  //   this.evaluated = this.courseSection.evaluated
  //   this.ponderation = this.courseSection.evaluation_weighting_prc
  //   this.body = { ...this.form, evaluated: this.evaluated, ponderation: this.ponderation }
  //   console.log(this.courseSection, 'EDIT')
  // },
  computed: {
    ...mapGetters({
      error: 'error'
    }),
    ...mapState({
      user: ({ session }) => session.user
    }),
    // isConfirmDisabled() {
    //   return Object.values(this.form).some(value => !value)
    // },
  },
  methods: {
    ...mapActions({
      clear: 'removeError'
    }),
    moment: () => moment(),
    confirm() {
      let newBody = {}
      // newBody = { period_cut: this.form.period_cut }
      if(this.isEditing) {
        if(this.body.subject !== this.form.subject) {
          newBody = { ...newBody, subject: this.form.subject }
        }
        if(this.body.period_cut !== this.form.period_cut) {
          newBody = { ...newBody, period_cut: this.form.period_cut }
        }
        if(this.body.evaluated !== this.evaluated) {
          newBody = { ...newBody, evaluated: this.evaluated }
        }
        if(this.body.ponderation !== this.ponderation) {
          newBody = { ...newBody, evaluation_weighting_prc: parseInt(this.ponderation) }
        }
      } else {
        if(this.body.date !== this.form.date) {
          newBody = { ...newBody, date: this.form.date}
        }
        if(this.body.subject !== this.form.subject) {
          newBody = { ...newBody, subject: this.form.subject }
        }
        if(this.body.period_cut !== this.form.period_cut) {
          newBody = { ...newBody, period_cut: this.form.period_cut }
        }
        if(this.body.evaluated !== this.evaluated) {
          newBody = { ...newBody, evaluated: this.evaluated }
        }
        if(this.body.ponderation !== this.ponderation && this.evaluated) {
          newBody = { ...newBody, evaluation_weighting_prc: this.ponderation }
        }
      }

      this.$repository.lessons
        .edit(this.courseSection.id, newBody)
        .then(() => {
          this.$emit('edited-class')
          this.$message.success({
            message: `Se ha editado la clase con exito`,
            posdataon: `top-right`
          })
        })
        .catch(({ response: { data: value} }) => {
          this.$message.warning({
            title: "Información de la ponderación",
            message: `Ponderación a asignar: ${value.payload.weighting}%,
              Disponible: ${value.payload.available}%,`,
            duration: -1
          })
        })
    },
  },
  watch: {
    ponderation(value){
      if (value < 0) {
        this.ponderation = 0
      }
    },
    courseSection(value){
      // this.form.date = moment(value?.registered_at, 'YYYY-MM-DD')
      // console.log(value)
      this.form.date = value.date
      this.form.subject = value.subject
      this.form.period_cut = value.period_cut
      this.evaluated = this.courseSection.evaluated
      this.ponderation = this.courseSection.evaluation_weighting_prc
      this.body = { ...this.form, evaluated: this.evaluated, evaluation_weighting_prc: this.ponderation }
      this.evaluated = value.evaluated
    }
  }
}
</script>
