<template>
  <div class="space-y-4">
    <div class="mb-2 flex items-center">
      <span class="mr-2">
          <a-button @click="$router.go(-1)">
              Regresar
          </a-button>
      </span>
    </div>
    <a-table
        toolbar-column-filter
        :columns="columns"
        :source="pagination.data"
        :pagination="pagination"
        @paginate="search({ page: $event, order_by: 'date' })"
        @toolbar(refresh)="search({ page: 1, order_by: 'date' })"
        :loading="getLoading">
      <template #td(evaluated)="{ item }">
        {{ item.evaluated ? 'SI' : 'NO' }}
      </template>
      <template #td(actions)="{ item }">
        <a-dropdown>
          <template #menu>
            <a-dropdown-item v-if="user.isAdmin" @click="lesson = item; modals.edit = true">
              <div class="flex gap-2 justify-between">
                <span>
                  Editar
                </span>
                <a-loader class="w-5 h-5" v-if="loadings.destroy" />
              </div>
            </a-dropdown-item>
            <a-dropdown-item v-if="user.isAdmin" @click="confirmDestroy(item)" :disabled="loadings.destroy">
              <div class="flex gap-2 justify-between">
                <span>
                  Eliminar
                </span>
                <a-loader class="w-5 h-5" v-if="loadings.destroy" />
              </div>
            </a-dropdown-item>
          </template>
        </a-dropdown>
<!--        <index-table-dropdown-->
<!--            v-if="!loadings.lessons"-->
<!--            :lesson="item"-->
<!--            @register-lesson="setLesson('register', $event)"-->
<!--            @edit-lesson="setLesson('edit', $event)"-->
<!--            @registered-class="search({ order_by: 'date' })"-->
<!--            @delete="search({ order_by: 'date' })" />-->
      </template>
    </a-table>
    <!-- <graph-view
      :show="modals.graph"
      :course-section="courseSectionModel"
      v-if="filters.courseSection"
      @cancel="modals.graph = false" /> -->
    <register-class
        :show="modals.register"
        :course-section="lesson"
        @registered-class="modals.register = false; search({ page: 1, order_by: 'date' })"
        @cancel="modals.register = false" />
    <edit-class
        :is-editing="true"
        :show="modals.edit"
        :course-section="lesson"
        @edited-class="modals.edit = false; search({ page: 1, order_by: 'date' })"
        @cancel="modals.edit = false" />
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
// import IndexTableDropdown from '../../lessons/partials/index-table-dropdown.vue'
import RegisterClass from '../../lessons/partials/register-class'
import EditClass from '../../lessons/partials/edit-class'
import moment from '@/filters/moment'
// import GraphView from './chart-view.vue'

export default {
  name: 'lessons',
  components: {
    // IndexTableDropdown,
    RegisterClass,
    EditClass
  },
  data: () => ({
    filters: {
      period: null,
      courseSection: null,
    },
    lesson: {},
    courseSections: [],
    lessons: { data: [] },
    loadings: {
      courseSections: false,
      lessons: false,
      destroy: false
    },
    modals: {
      graph: false,
      register:false,
      edit: false
    },
  }),
  computed: {
    ...mapState({
      user: ({ session }) => session.user,
      pagination: state => state.lessons.pagination,
      loadingLessons: state => state.lessons.loading
    }),
    getLoading() {
      return this.loadingLessons
    },
    options() {
      return this.courseSections
          .map(({ course_id, section_id, course: { title }, section: { letter_code }, id }) => ({
            label: `${title}, Sección ${letter_code}`,
            value: id,
            course_id,
            section_id
          }))
    },
    columns() {
      return [
        { title: 'Tema', key: 'subject', display: true},
        // { title: 'Fecha', key: 'date', },
        { title: 'Corte de período', key: 'period_cut', display: true },
        { title: 'Fecha sugerida',key: 'date',  mutate: ({ key }) => key ? moment(key, 'DD-MM-YYYY') : '', display: true },
        { title: 'Fecha de registro',key: 'registered_at',  mutate: ({ key }) => key ? moment(key, 'DD-MM-YYYY') : '', display: true },
        { title: 'Carga de notas',key: 'evaluated_at',  mutate: ({ key }) => key ? moment(key, 'DD-MM-YYYY') : '', display: true },
        { title: '¿Evaluada?', slot: 'evaluated', display: true },
        { title: '% de evaluación', key: 'evaluation_weighting_prc', display: true },
        { title: 'Acciones', slot: 'actions', alignment: 'right', columnFilterable: false, display: this.user.isAdmin },
      ].filter($0 => $0.display)
    },
    courseSection () {
      const found = this.courseSections.find(current => current.id === this.filters.courseSection)

      if (found) {
        const { course_id, section_id } = found
        return { course_id, section_id }
      }

      return {}
    },
    courseSectionModel() {
      return this.courseSections.find(current => current.id === this.filters.courseSection)
    },
    tooltipGenerateMessage() {

      if (!this.filters.period) {
        return 'Por favor, seleccione un período'
      }

      if (!this.options.length) {
        return 'No hay materias disponibles para generar una clase'
      }

      return 'Generar clase para el período actual'
    }
  },
  methods: {
    ...mapActions({
      fetchLessons: 'lessons/fetchAll'
    }),
    confirmDestroy(lesson) {
      this.$message.warning({
        title: 'Estás por eliminar una clase',
        message: '¿Estas seguro de querer continuar con esta acción?',
        buttons: ({ close }) => [
          { title: 'Si, estoy seguro', action: () => {
              this.destroy(lesson)
              close()
            }},
          { title: 'Cerrar', action: () => {
              close()
            }},
        ]
      })
    },
    destroy (lesson) {
      this.loadings.destroy = !this.loadings.destroy
      this.$repository.lessons
        .delete(lesson.id)
        .then(() => {
          this.fetchLessons({
            course_id: this.$route.params.course_id,
            section_id: this.$route.params.section_id,
            filters: {
              order_by: 'date'
            }
          })
        })
        .finally(() => this.loadings.destroy = !this.loadings.destroy)
    },
    setLesson(type, event){
      this.lesson = {}
      this.lesson = event
      if(type === 'register') {
        this.modals.register = true
      } else {
        this.modals.edit = true
      }
    },
    fetch() {
      this.lessons = { data: [] }
      this.filters.courseSection = this.$coursesection.id
      this.loadings.courseSections = !this.loadings.courseSections
      this.$repository.teachers
          .courses(this.$route.params.teacher, { period: this.$route.params.period, limit: 0, with: 'lessons' })
          .then(({ data }) => {
            this.courseSections = data
          })
          .finally(() => this.loadings.courseSections = !this.loadings.courseSections)
    },
    search(query) {
      // const found = this.courseSections.find(current => current.id === this.filters.courseSection)
      this.fetchLessons({
        course_id: this.$route.params.course_id,
        section_id: this.$route.params.section_id,
        filters: query })
    },
    toggleGenerate () {
      if (this.options.length) {
        this.modals.generate = !this.modals.generate
      }
    }
  },
  watch: {
    'filters.period'(val) {
      if (val) {
        this.fetch(val)
      }
    },
    'filters.courseSection'(courseSection) {
      // this.$router.push({query: {coursesection: this.filters.courseSection}})
      const found = this.courseSections.find(current => current.id === courseSection)
      if (found) {
        this.$coursesection.id = this.filters.courseSection
        this.loadings.lessons = !this.loadings.lessons
        this.fetchLessons({
          course_id: found.course.id,
          section_id: found.section.id,
          filters: {
            order_by: 'date'
          }
        })
            // .then(pagination => {
            //   this.lessons = pagination
            // })
            .finally(() => this.loadings.lessons = !this.loadings.lessons)
      }
    },
  },
  mounted () {
    // this.fetch()
    // this.filters.courseSection = this.$route.query.coursesection
    // if (this.$route.query.coursesection) {
    //   this.filters.courseSection = this.$coursesection.id
    // }
    this.fetchLessons({
      course_id: this.$route.params.course_id,
      section_id: this.$route.params.section_id,
      filters: {
        order_by: 'date'
      }
    })
  }
}
</script>
