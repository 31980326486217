<template>
  <a-modal
    :disabled-confirm="isConfirmDisabled"
    title="Crear clase"
    :show="show"
    @confirm="confirm"
    @cancel="$emit('cancel')">

    <div class="space-y-4">
      <a-input
        id="start_date"
        type="date"
        :error="error('start_date')"
        @input="clear('start_date')"
        label="Fecha de inicio"
        v-model="form.start_date" />

      <a-select
        label="Corte de periodo"
        :error="error('period_cut')"
        @input="clear('period_cut')"
        :source="[{ label: 'Primer corte', value: 1 }, { label: 'Segundo corte', value: 2 }]"
        v-model="form.period_cut" />
    </div>
  </a-modal>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
export default {
  props: {
    show: { required: true, type: Boolean },
    courseSection: { required: true, type: Object },
  },
  data: () => ({
    form: {
      start_date: '',
      period_cut: ''
    },
    loadings: {
      courseSections: false,
      store: false
    }
  }),
  computed: {
    ...mapGetters({
      error: 'error'
    }),
    ...mapState({
      user: ({ session }) => session.user
    }),
    isConfirmDisabled() {
      return Object.values(this.form).some(value => !value)
    },
    // minDate() {
    //   return
    // }
  },
  methods: {
    ...mapActions({
      clear: 'removeError'
    }),
    confirm() {
      const { section_id, course_id } = this.courseSection

      const body = {
        ...this.form,
        section_id,
        course_id
      }

      this.$repository.lessons
        .generate(body)
        .then(() => {
          this.$message.success({
            message: `Se ha generado la clase con exito`,
            posdataon: `top-right`
          })
          this.$emit('cancel')
        })

    }
  }
}
</script>
