<template>
    <div class="p-1">

        <div class="flex space-x-2 justify-end mb-2" >
            <a-button :to="{ name: 'courses.create' }" v-if="user.isAdmin" class="w-full md:w-auto">
                Crear materia
            </a-button>
            <a-button :to="{ name: 'courses.all' }" v-if="user.isAdmin" class="w-full md:w-auto">
                Materias sin asignar
            </a-button>
            <a-period-accessor v-model="period" class="bg-white" />
        </div>

        <router-view
            :course-sections="courseSections"
            :loading-course-sections="loadings.courseSections"
            @specialization="specialization = $event" />
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    data: () => ({
        columns: [
            { title: 'Código', key: 'code', enabled: true },
            { title: 'Título', key: 'title', enabled: true },
            { title: 'Créditos', key: 'credits', enabled: true },
            { title: 'Estado', key: 'enabled', slot: 'enabled', enabled: true },
            { title: 'Acciones', slot: 'actions', style: () => ({ width: `180px` }), alignment: 'right', enabled: true },
        ],
        query: {
            limit: 10,
            enabled: true,
            search: ''
        },
        specialization: null,
        period: null,
        timeout: null,
        semester: null,
        periods: [],
        courseSections: [],
        loadings: {
            periods: false,
            courseSections: false
        }
    }),
    computed: {
        ...mapState({
            user: state => state.session.user,
        }),
        specializations() {
            let data = []
            this.courseSections.forEach($0 => {
                if (!data.some($1 => $1.id === $0.specialization.id)) {
                    data.push($0.specialization)
                }
            })

            return data
        },
        semesters() {
            let semesters = []
            this.periods.find($0 => $0.id === this.period)
                ?.pensums
                ?.flatMap($0 => $0.semesters)
                ?.filter($0 => $0.specialization_id === this.specialization)
                ?.forEach($0 => {
                    if (!semesters.some($1 => $1.value === $0.id)) {
                        semesters.push({
                            label: $0.number,
                            value: $0.id,
                        })
                    }
                })

            return semesters.sort((a, b) => a.value === b.value ? 0 : a.value > b.value ? 1 : -1)
        },
        periodSemesterComposition() {
            return `${this.period}|${this.semester}`
        },
    },
    methods: {
        fetchAll(period) {
            this.$repository.courseSections
                .index(this.$repositoryUtils.withoutInvalidValues({ limit: 0, period, with: 'specialization,semester,teacher' }))
                ._loading(() => this.loadings.courseSections = !this.loadings.courseSections)
                .then(({ data: courseSections }) => this.courseSections = courseSections)

            if (this.$route.params.period_id) {
                this.$router.replace({ params: this.$repositoryUtils.withoutInvalidValues({ period_id: period }) })
                    .catch(() => null)
            }
        },
        fetch () {
            this.$repository.periods
                .index({ limit: 0, with: 'pensums.semesters', sort_by: 'year,asc' })
                ._loading(() => this.loadings.periods = !this.loadings.periods)
                .then(({ data: periods }) => {
                    this.periods = periods
                    if (!this.period) {
                        this.period = periods.find($0 => $0.enabled)?.id
                    }
                    this.$repository.periods
                      .coursesBySpecialization(this.$route.query.period)
                      ._loading(() => this.loadings.courseSections = !this.loadings.courseSections)
                      .then(({ data }) => {
                        this.courseSections = data.specializations
                      })
                })
            // this.$repository.periods
            //     .coursesBySpecialization(this.$route.query.period)
            //     .then(({ data }) => {
            //         this.courseSections = data.specializations
            //         console.log(this.courseSections)
            //     })
        }
    },
    watch: {
        period(period) {
            if (this.$route.query.period !== period) {
              const query = this.$repositoryUtils.withoutInvalidValues({ period })
              this.$router.replace({ query })
                .catch(() => null)
            }

            if (this.$route.query.period) {
                this.$repository.periods
                    .coursesBySpecialization(this.$route.query.period)
                    ._loading(() => this.loadings.courseSections = !this.loadings.courseSections)
                    .then(({ data }) => {
                        this.courseSections = data.specializations
                    })
            }


        }

        // '$route.params.specialization_id'(id) {
        //     this.specialization = parseInt(id)
        // },
        // specialization(id) {
        //     this.specialization = id
        //     if (this.$route.params.specialization_id) {
        //         this.$router.replace({ params: { specialization_id: id } })
        //     }
        // }
    },
    mounted() {
        this.fetch()
    },
    created() {
        this.query = {
            ...this.query,
            ...this.$route.query,
            enabled: this.query.enabled,
        }

        this.period = parseInt(this.$route.params.period_id) || parseInt(this.$route.query.period) || null
        this.semester = this.$route.params.semester_id || this.$route.query.semester || null
        this.specialization = this.$route.params.specialization_id

        // const query = this.$repositoryUtils.withoutInvalidValues({ period })
        // this.$router.replace({ query })
        //   .catch(() => null)
    }
}
</script>
