<template>
    <div>
        <template v-if="loadingCourseSections">
            <div class="flex flex-wrap -mx-2">
                <div class="my-2 py-2 overflow-x-auto mt-3 w-full md:w-2/4 lg:w-1/3 px-2" v-for="n in 6" :key="n">
                    <a-skeleton class="h-24 w-full" />
                </div>
            </div>
        </template>
        <template v-else>
            <template v-if="(courseSections || []) && $route.query.period">
                <div class="flex flex-wrap -mx-2">
                    <div class="my-2 py-2 overflow-x-auto mt-3 w-full md:w-2/4 lg:w-1/3 px-2" v-for="specialization in courseSections" :key="specialization.id">
                        <router-link :to="{ name: 'courses.details', params: { period: period, specialization: specialization.id, pensum: specialization.pensum_id }, query: { period: $route.query.period }}">
                            <div class="align-middle inline-block min-w-full bg-white shadow overflow-hidden sm:rounded-lg border-b border-gray-200 p-3 flex items-start">
                                <span class="inline-block rounded-full h-4 w-4 mr-3 mt-2" :style="{ background: specialization.color}"></span>
                                <h4 class="inline-block mb-6">
                                    <b>{{ specialization.title }}</b> <br>
                                    <span>{{ specialization.courses.length }} Materias</span>
                                </h4>
                            </div>
                        </router-link>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="flex p-3 justify-center">
                    <h1 v-if="!period" class="text-2xl p-8">
                        Seleccione un período
                    </h1>
                    <h1 class="text-2xl p-8" v-else>No se encontraron materias</h1>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    props: {
        courseSections: { required: false, type: Array, default: () => [] },
        loadingCourseSections: { required: false, type: Boolean, default: false }
    },
    data: () => ({
        pensum: 0
    }),
    computed: {
        ...mapState({
            loading: state => state.periods.loading,
            // courseSections: ({ courseSections }) => courseSections.all,
            // loadingCourseSections: ({ courseSections }) => courseSections.loading,
        }),
        period() {
            return this.$route.query.period
        },
        semester() {
            return this.$route.query.semester
        },
        specializations() {
            let data = []
            this.courseSections.forEach($0 => {
                // if (!data.some($1 => $1.id === $0.specialization.id)) {
                //     data.push({
                //         ...$0.specialization,
                //         courses: this.courseSections
                //             .filter($1 => $1.specialization.id === $0.specialization.id)
                //             .map($1 => ({
                //                 ...$1.course,
                //                 section: $0.section
                //             }))
                //     })
                // }
                data.push($0.title)
            })

            return data
        }
    },
    methods: {
        getSections(specialization) {
            const total = specialization.courses
                // .filter((course, index, array) => array.some(it => it.section.id == course.section.id))
                .map($0 => $0.section.id)

            const set = new Set(total)

            return set.size
        },
        handlePensum(pensumID){
            console.log(pensumID)
        }
    },
    watch: {
        pensum(id) {
            console.log(id)
            // if (this.$route.query.pensum !== this.pensum) {
            //     const query = this.$repositoryUtils.withoutInvalidValues({ id })
            //     this.$router.replace({ ...query })
            //         .catch(() => null)
            // }
        }
    },
}
</script>
