<template>
    <div>
        <div class="mb-2 flex items-center" v-if="component === 'course-table'">
            <span class="mr-2">
                <a-button @click="$router.back()">
                    Regresar
                </a-button>
            </span>
        </div>

        <template v-if="loadingCourses">
            <a-skeleton v-for="n in 10" :key="n" class="h-10 w-full" />
        </template>

        <template v-else>
            <div>
                <component
                  :is="component"
                  :source="courses"
                  :specialization="specialization"
                  @handle-table="handler"
                  @fetch-courses="fetchCourses"
                  @teacher-assigned="teacherAssigned"
                />
            </div>
        </template>

        <course-section-generate-lesson
            :show="Object.keys(modals.generateLesson).length > 0"
            :course-section="modals.generateLesson"
            @cancel="modals.generateLesson = {}" />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import CourseSectionGenerateLesson from '../components/course-section-generate-lesson.vue'
import CourseTable from './table.vue'
import CourseShow from './show.vue'

export default {
    components: {
        // CoursesDetailsTableDropdown,
        CourseSectionGenerateLesson,
        CourseTable,
        CourseShow
    },
    props: {
        courseSections: { required: false, type: Array, default: () => [] },
        loadingCourseSections: { required: false, type: Boolean, default: false }
    },
    data: () => ({
        segments: [
            { id: 0, title: "Activos" },
            { id: 1, title: "Inactivos" },
        ],
        component: 'course-table',
        courses: [],
        body: {
            'specialization_id': 0,
            'period_id': 0,
            'pensum_id': 0,
            'enabled': 0
        },
        filters: {
            semester: null,
            section: null
        },
        modals: {
            generateLesson: {}
        },
        control: 0,
        loadingCourses: false
    }),
    computed: {
        ...mapState({
            user: ({ session }) => session.user,
            loading: state => state.periods.loading,
            loadingTeachers: ({ courseSections }) => courseSections.loading,
            teachers: ({ teachers }) => teachers.all
        }),
        columns() {
            return [
                { title: "Código", key: "code", display: true },
                { title: "Materia", key: "title", filter: true, display: true },
                { title: "Secciones", key: "sections.length", filter: true, keyFilter: 'section', display: true },
                { title: "Semestre", key: "semesters.0.number", filter: true, display: true },
                { title: "Créditos", key: "credits", display: true },
                // { title: "Estado", key: "enabled", mutate: ({ key }) => key ? 'Activa' : 'Inactiva', display: true },
                { title: "Acciones", slot: "actions", display: this.user.isAdmin },
            ].filter($0 => $0.display)
        },
        // specializations() {
        //     let data = []
        //     let courses = []
        //     this.courseSections.forEach($0 => {
        //         if (!data.some($1 => $1.id === $0.specialization.id)) {
        //             data.push({
        //                 ...$0.specialization,
        //                 courses: this.courseSections
        //                     .filter($1 => {
        //                         console.log($1.specialization.id)
        //                         return $1.specialization.id === $0.specialization.id
        //                     })
        //                     .map($1 => {
        //                         return ({
        //                             ...$1.course,
        //                             course_id: $1.course.id,
        //                             section_id: $1.section.id,
        //                             course_section_id: $1.id,
        //                             section: $1.section,
        //                             semester_number: $1.semester.number,
        //                             teacher: $1.teacher,
        //                             letter_code: $1.section.letter_code
        //                         })
        //                     })
        //             })
        //         }
        //
        //         let specialization = data.find($1 => $0.specialization.id === $1.id)
        //         if (specialization) {
        //
        //             const course = specialization.courses.find($1 => $1.id === $0.id)
        //
        //             if (course) {
        //                 if (course.course_section_id === $0.course_section_id) {
        //                     specialization.courses.push({
        //                         ...$0.course,
        //                         course_section_id: $0.id,
        //                         section: $0.section,
        //                         semester_number: $0.semester.number,
        //                         teacher: $0.teacher
        //                     })
        //                 }
        //             } else {
        //
        //                 specialization.courses.forEach($1 => {
        //                     if (!courses.some($2 => $2.course_section_id === $1.course_section_id)) {
        //                         courses.push($1)
        //                     }
        //                 })
        //
        //                 specialization.courses = courses
        //             }
        //             courses = []
        //         }
        //     })
        //
        //     return data
        // },
        // courses() {
        //     return this.specialization.courses
        // },
        specialization() {
            const { specialization } = this.$route.params
            if (specialization) {
                return this.courseSections?.find($0 => $0.id === parseInt(specialization))
            }
            return null
        },
        // semesters() {
        //     let semesters = new Set(this.specialization.courses.flatMap($0 => $0.semester_number))
        //     return [...semesters].map($0 => $0)
        // },
        // sections() {
        //     let sections = new Set(this.specialization.courses.flatMap($0 => $0.section.letter_code))
        //     return [...sections]
        // },
        filteredCoursesBySemester() {
            if (this.filters.semester) {
                return this.courses.filter($0 => this.$options.filters.roman($0.semester_number) === this.filters.semester)
            }

            return this.courses
        },
        filteredCourses() {
            const { semester, section } = this.filters
            return this.courses
                .filter($0 => semester ? $0.semester_number === semester : true)
                .filter($0 => section ? $0.section.letter_code === section : true)
                .filter($0 => this.control ? $0.enabled === (this.control === 0) : true)
                .sort((a, b) => a.title > b.title ? 1 : -1)
        }
        // filteredStatus() {
        //     // console.log(this.courses)
        //     let status = this.courses && 0
        //     console.log(status)
        //     return this.courses
        //         .filter($0 => {
        //             // console.log($0.enabled)
        //             return status ? $0.enabled === status : true
        //         })
        //         .sort((a, b) => a.title > b.title ? 1 : -1)
        // }
    },
    methods: {
        ...mapActions({
            fetchTeachers: 'teachers/index'
        }),
      teacherAssigned (event) {
        this.loadingCourses = true

        this.body = {
          'specialization_id': parseInt(this.$route.params.specialization),
          'period_id': parseInt(this.$route.query.period),
          'pensum_id': parseInt(this.$route.params.pensum),
          'enabled': true
        }

        this.$repository.specializations
          .courses(this.body)
          .then(({ data }) => {
            this.courses = data
          })
          .finally(() => {
            this.courses = this.courses.find($0 => {
              return $0.id === event[1].id
            })
            this.component = event[0]
            this.loadingCourses = false
          })


      },
      handler (event) {
          this.component = event[0]
          this.courses = event[1]
      },
      fetchCourses() {
        this.loadingCourses = true

        this.body = {
          'specialization_id': parseInt(this.$route.params.specialization),
          'period_id': parseInt(this.$route.query.period),
          'pensum_id': parseInt(this.$route.params.pensum),
          'enabled': true
        }

        this.$repository.specializations
          .courses(this.body, { order_by: 'tilte,asc' })
          .then(({ data }) => {
            this.component = 'course-table'
            this.courses = data.sort((a, b) => a.title > b.title ? 1 : -1)
            console.log(this.courses)
          })
          .finally(() => (this.loadingCourses = false))

      }
    },
    watch: {
        '$route.params.specialization_id'() {
            this.filters.semester = null
            this.filters.section = null
        },
        '$route.query.period'() {
          // const newPeriod = this.$route.query.period
          // const newPeriod = {
          //   period: this.$route.query.period
          // }
          // this.$router.replace({ params: newPeriod })
          //   .catch(() => null)
          this.fetchCourses()
        }
    },
    mounted() {
        // this.fetchTeachers({ limit: 0, enabled: true })
        this.$emit('specialization', parseInt(this.$route.params.specialization))
        // this.body = {
        //     'specialization_id': parseInt(this.$route.params.specialization),
        //     'period_id': parseInt(this.$route.query.period),
        //     'pensum_id': parseInt(this.$route.params.pensum),
        //     'enabled': true
        // }
        this.fetchCourses()

    }
}
</script>
