<template>
  <a-modal
    :disabled-confirm="isConfirmDisabled || (evaluated && !ponderation)"
    :title="'Registrar clase'"
    :show="show"
    @confirm="confirm"
    @cancel="$emit('cancel')">

    <div class="space-y-4">
      <a-input
        id="start_date"
        @input="clear('subject')"
        label="Tema"
        v-model="form.subject" />

      <a-input
        id="start_date"
        type="date"
        :error="error('date')"
        @input="clear('date')"
        label="Fecha de inicio"
        v-model="form.date" />

      <a-select
        label="Corte de periodo"
        :error="error('period_cut')"
        @input="clear('period_cut')"
        :source="[{ label: 'Primer corte', value: 1 }, { label: 'Segundo corte', value: 2 }]"
        v-model="form.period_cut" />

      <div class="flex">
        <div class="flex-1">
          <h2 class="font-medium block">Evaluado</h2>
          <a-switch
            id="evaluated"
            @input="clear('evaluated')"
            v-model="evaluated" >
          </a-switch>
        </div>
        <div v-if="evaluated" class="flex-1">
          <h2 class="font-medium block">Ponderación</h2>
          <input
            id="ponderation"
            type="number"
            :min="0"
            class="p-2 border border-dgray rounded-md"
            @input="clear('evaluated')"
            v-model="ponderation" >
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
// import moment from "@/filters/moment";
export default {
  name: 'register-class',
  props: {
    show: { required: true, type: Boolean },
    courseSection: { required: false },
  },
  emits: ['cancel'],
  data: () => ({
    form: {
      subject: '',
      date: '',
      period_cut: '',
    },
    evaluated: false,
    course_section_id: 0,
    ponderation: 0,
    loadings: {
      courseSections: false,
      store: false
    }
  }),
  // mounted() {
  //   this.form.date = this.courseSection.date
  //   this.form.subject = this.courseSection.subject
  //   this.form.period_cut = this.courseSection.period_cut
  //   this.evaluated = this.courseSection.evaluated
  //   this.ponderation = this.courseSection.evaluation_weighting_prc
  // },
  computed: {
    ...mapGetters({
      error: 'error'
    }),
    ...mapState({
      user: ({ session }) => session.user
    }),
    isConfirmDisabled() {
      return Object.values(this.form).some(value => !value)
    },
    // minDate() {
    //   return
    // }
  },
  methods: {
    ...mapActions({
      clear: 'removeError'
    }),
    confirm() {
      // this.$emit('cancel')
      // const { section_id, course_id } = this.courseSection

      // this.$repository.sections
      //   .courseSection(section_id, course_id)
      //   .then(({ data }) => {
        let body = {}
        if (this.evaluated) {
          body = {
            ...this.form,
            evaluated: this.evaluated,
            evaluation_weighting_prc : parseInt(this.ponderation)
            // course_section_id: data.id
          }
        } else {
          body = {
            ...this.form,
            evaluated: this.evaluated,
            // course_section_id: data.id
          }
        }

        this.$repository.lessons
          .register(this.courseSection.id, body)
          .then(() => {
            this.$message.success({
              message: `Se ha registrado la clase con exito`,
              posdataon: `top-right`
            })
            this.$emit('registered-class')
          })
          .catch(({ response: { data: value} }) => {
            this.$message.warning({
              title: "Información de la ponderación",
              message: `Ponderación a asignar: ${value.payload.weighting}%,
              Disponible: ${value.payload.available}%,`,
              duration: -1
            })
          })
        // })
    },
  },
  watch: {
    ponderation: (value) => {
      if (value < 0) {
        this.ponderation = 0
      }
    },
    courseSection(value){
      this.form.date = value.date
      this.form.subject = value.subject
      this.form.period_cut = value.period_cut
      this.body = { ...this.form, evaluated: this.evaluated, evaluation_weighting_prc: this.ponderation }
    }
  }
}
</script>
